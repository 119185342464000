/////////////////// Mo
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");

///// Font mixin

@mixin fontSize($min-size, $size, $max-size, $line-height: 1.5, $total: 1440) {
  font-size: clamp(#{$min-size}, #{$size}vw, #{$max-size}) !important;
  line-height: #{$line-height};
}

///// Font

html {
  font-size: 62.5%;
}

body {
  font-size: 1.6rem;
  @include fontSize(1.4rem, 1.6, 1.8rem);
  font-family: $font-family-text;
  color: $color-text;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  * {
    font-family: $font-family-text;
    // color: $color-text;
    // line-height: 1.1;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-family-title;
  font-weight: 600;
}

p,
blockquote,
a,
span {
  font-family: $font-family-text;
  font-weight: normal;
}

h1,
h1 span {
  font-weight: bold;

  @include fontSize(
    #{$title-size-mobile}rem,
    $title-size-desktop,
    #{$title-size-desktop}rem,
    1.1
  );
}

h2,
h2 span {
  font-weight: bold;

  @include fontSize(
    #{$subtitle-size-mobile}rem,
    $subtitle-size-desktop,
    #{$subtitle-size-desktop}rem
  );
}

p,
p span,
a,
a span {
  font-weight: normal;

  @include fontSize(
    #{$text-size-mobile}rem,
    $text-size-desktop,
    #{$text-size-desktop}rem,
    1.25
  );
}
