
            @import "@/scss/common/_vars.scss";
            @import "@/scss/common/_breakpoints.scss";
            @import "@/scss/common/_fonts.scss";
            @import "@/scss/common/_reset.scss";
          











































.languages {
  padding: 2px;
  display: flex;
  flex-direction: column;
  color: $color-text-inverse;
  z-index: 1;

  @include for-tablet-landscape-up {
    flex-direction: row;
  }

  a {
    cursor: pointer;
    opacity: 0.9;
    text-transform: uppercase;
    // transition: text-shadow $basic-transition-200;

    & ~ a {
      margin: 1rem 0;

      @include for-tablet-landscape-up {
        margin: 0;
      }
    }

    & ~ a::before {
      content: "";
      margin: 0;
      pointer-events: none;
      // text-shadow: none;
      font-weight: normal !important;

      @include for-tablet-landscape-up {
        content: "|";
        margin: 0 6px;
      }
    }

    &.selected {
      pointer-events: none;
    }

    &.selected,
    &:hover {
      opacity: 1;
      // text-shadow: 0 0 1px $color-text-inverse;
      font-weight: bold;
    }

    &:hover::before {
      // text-shadow: none;
      font-weight: normal !important;
      opacity: 0.9;
    }
  }
}
