
            @import "@/scss/common/_vars.scss";
            @import "@/scss/common/_breakpoints.scss";
            @import "@/scss/common/_fonts.scss";
            @import "@/scss/common/_reset.scss";
          


















































































span {
  display: inline-block;
  line-height: 1.5 !important;

  a {
    display: flex;
    align-items: center;
    display: inline;
    padding: 0 12px;
    transition: font-weight $basic-transition-100;

    &:hover {
      font-weight: bold;
    }

    * {
      color: $color-backdrop;
      font-weight: bold;
    }

    img {
      max-width: 2rem;
      filter: invert(36%) sepia(8%) saturate(1093%) hue-rotate(164deg)
        brightness(96%) contrast(87%);
      margin-right: 1rem !important;
    }
  }
}

.fade-text-enter-active,
.fade-text-leave-active {
  transition: opacity 0.1s linear;
}
.fade-text-enter,
.fade-text-leave-to {
  opacity: 0.3;
}
