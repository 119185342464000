$color-text: #73838d;
$color-text-inverse: #ffffff;

$color-black: #000000;
$color-white: #ffffff;
$color-white--darker: #f1f2f2;
$color-gray: #d0d0d0;
$color-gray--darker: #d8e0eb;

$color-primary: #c30045;
$color-primary--dark: #8b2d46;
$color-primary--darker: #7a1e37;
$color-secondary: #0b5491;
$color-accent: #e2477d;
$color-backdrop: #51626f;
$color-backdrop--lighter: #6d7a84;
$color-border: #e0d9cb;

$font-family-title: "Montserrat", sans-serif;
$font-family-text: "Montserrat", sans-serif;

$ui-padding: 3.6rem;
$ui-padding-mobile: 2.2rem;

$title-size-mobile: 3.2;
$title-size-desktop: 3.2;

$subtitle-size-mobile: 2;
$subtitle-size-desktop: 2.2;

$text-size-mobile: 1.5;
$text-size-desktop: 1.5;

$basic-transition-100: 100ms cubic-bezier(0.86, 0, 0.07, 1);
$basic-transition-200: 200ms cubic-bezier(0.86, 0, 0.07, 1);
$basic-transition-300: 300ms cubic-bezier(0.86, 0, 0.07, 1);
$basic-transition-500: 500ms cubic-bezier(0.86, 0, 0.07, 1);
$basic-transition-1000: 1s cubic-bezier(0.86, 0, 0.07, 1);
