
            @import "@/scss/common/_vars.scss";
            @import "@/scss/common/_breakpoints.scss";
            @import "@/scss/common/_fonts.scss";
            @import "@/scss/common/_reset.scss";
          
#krpanoObject {
  z-index: 1000;
}

body > #krpanoObject {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.btn {
  color: $color-primary;
  background-color: $color-white;
  text-decoration: none;
  transition: all $basic-transition-300;
  font-weight: bold !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.6rem 4.2rem !important;
  width: auto;

  &_shadow {
    box-shadow: 0px 0px 120px 10px #00000047;
  }

  &:hover {
    color: $color-white;
    background-color: $color-primary--darker;
  }

  &:active {
    color: $color-white;
    background-color: lighten($color-primary, 25%);
    transform: scale(0.95);
  }
}

::-webkit {
  &-scrollbar {
    width: 6px;

    &-track {
      border-radius: 50px;
      background: transparent;
      z-index: 2;
      outline: 2px solid $color-gray--darker;
    }

    &-thumb {
      border: 1px solid $color-white;
      border-radius: 50px;
      background: $color-gray--darker;
      z-index: 1;

      &:hover {
        background: darken($color-gray--darker, 10%);
      }
    }
  }
}

/* Tinybox styling */
.tinybox {
  z-index: 8000 !important;
  background: rgba(0, 0, 0, 1) !important;

  &__content {
    &__image {
      border-radius: 4px !important;
    }

    &__control--close {
      top: 16px !important;
    }
  }
}

.gallery {
  .flicking-camera {
    display: inline-flex !important;
    align-items: center;
    justify-content: center;
  }
}

.modal {
  .flicking-camera {
    display: inline-flex !important;
  }
}

.invisible {
  opacity: 0 !important;
  pointer-events: none !important;
  height: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  z-index: -1 !important;
}

/* Inner image zoom styling */

.iiz__hint {
  display: none !important;
}
