.gui {
  z-index: 1001;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;

  * {
    pointer-events: all;
  }

  &__element {
    position: absolute;

    &--top-right {
      top: $ui-padding-mobile;
      right: $ui-padding-mobile;

      @include for-tablet-landscape-up {
        top: $ui-padding;
        right: $ui-padding;
      }
    }

    &--top-left {
      top: $ui-padding-mobile;
      left: $ui-padding-mobile;

      @include for-tablet-landscape-up {
        top: $ui-padding;
        left: $ui-padding;
      }
    }

    &--bottom-right {
      bottom: $ui-padding-mobile;
      right: $ui-padding-mobile;

      @include for-tablet-landscape-up {
        bottom: $ui-padding;
        right: $ui-padding;
      }
    }

    &--bottom-left {
      bottom: $ui-padding-mobile;
      left: $ui-padding-mobile;

      @include for-tablet-landscape-up {
        bottom: $ui-padding;
        left: $ui-padding;
      }
    }

    &--bottom-center {
      bottom: $ui-padding-mobile;
      left: 50%;
      transform: translateX(-50%);

      @include for-tablet-landscape-up {
        bottom: $ui-padding;
      }
    }

    &--top-center {
      top: $ui-padding-mobile;
      left: 50%;
      transform: translateX(-50%);

      @include for-tablet-landscape-up {
        top: $ui-padding;
      }
    }
  }

  &__logo {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    transition: all $basic-transition-200;

    width: 20%;
    max-width: 27px;
    min-width: 20px;
    opacity: 1;

    z-index: 3001;

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
      object-position: 0 0;
    }

    &:hover {
      opacity: 0.8;
    }

    @include for-tablet-landscape-up {
      max-width: 37px;
    }
  }

  &__close {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-self: center;
    grid-gap: 0.5rem;
    text-decoration: none;

    img {
      max-width: 3rem;
      transition: all $basic-transition-200;
    }

    span {
      @include fontSize(1.1rem, 1.3, 1.4rem);
    }

    &:hover img {
      transform: rotate(90deg);
    }
  }

  &__title {
    bottom: 0;
    left: $ui-padding;
    font-weight: bold;
    word-break: break-all;
    transition: padding $basic-transition-300;

    width: calc(100% - #{$ui-padding});
    background-color: rgba($color-primary, 0.5);
    backdrop-filter: blur(5px);
    padding: 1.6rem 1.6rem 1.6rem $ui-padding-mobile !important;

    &--description {
      padding: 1.6rem 1.6rem 5.6rem $ui-padding-mobile !important;
    }

    @include for-tablet-landscape-up {
      width: auto;
      max-width: calc(100% - #{$ui-padding});
      padding: 2.4rem 20rem 2.4rem $ui-padding !important;

      &--description {
        padding: 2.4rem 20rem 5.6rem $ui-padding !important;
      }
    }

    * {
      display: flex;
      width: auto;
      word-break: normal;
      color: $color-text-inverse;
    }

    h1,
    h2 {
      margin-top: 6px;
    }

    p {
      transition: height $basic-transition-300, width $basic-transition-300,
        padding $basic-transition-300;
      position: absolute;
      margin-top: 12px;
      // max-width: 500px;

      left: -36px;
      right: 24px;
      bottom: 0;
      height: 36px;

      background: white;
      padding: 0 !important;

      width: 200px;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;

      * {
        color: $color-text;
      }

      a {
        transition: background-color $basic-transition-300;
        padding: 6px 24px;
        background-color: transparent;
        position: absolute;
        left: 0;
        bottom: 0;
        height: 36px;
        width: auto;
        max-width: 200px;

        text-decoration: none;
        display: inline-flex;

        img.icon {
          margin-right: 3px;

          &--plus {
            filter: invert(37%) sepia(9%) saturate(987%) hue-rotate(164deg)
              brightness(93%) contrast(86%);
          }

          &--close {
            transform: rotate(-45deg);
            filter: invert(80%) sepia(90%) saturate(0%) hue-rotate(71deg)
              brightness(108%) contrast(105%);
          }
        }
      }
    }

    @include for-tablet-landscape-up {
      // bottom: 72px;
    }

    // &:hover {
    //   padding: 36px 256px 256px 36px !important;
    // }

    &.open {
      padding: 24px 256px 390px 36px !important;

      p {
        width: 100%;
        height: 372px;
        padding: 24px 24px 56px 72px !important;

        a {
          background-color: $color-backdrop--lighter;
          // justify-content: center;
          width: auto;

          span {
            color: $color-white;
          }

          img {
            filter: invert(99%) sepia(0%) saturate(2%) hue-rotate(214deg)
              brightness(113%) contrast(100%);
          }
        }
      }
    }
  }

  &__webvr {
    background-color: $color-backdrop;
    display: none;
    border-radius: 4px;
    cursor: pointer;

    a {
      color: $color-white;
      font-weight: bold;
      text-decoration: none;
      display: block;
      padding: 12px !important;
      transition: all $basic-transition-200;

      &:hover {
        padding: 12px 24px !important;
      }
    }

    @include for-tablet-landscape-up {
      display: block;
    }
  }

  &__overview {
    background-color: $color-primary;
    top: 0;
    right: 0;
    padding: 1.6rem $ui-padding-mobile;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-self: center;
    grid-gap: 0.5rem;
    text-decoration: none;

    * {
      @include fontSize(0.9rem, 1.1, 1.1rem);
      color: $color-white;
    }

    @include for-tablet-landscape-up {
    }

    .open-icon {
      position: relative;
      width: 100%;
      height: 1.8rem;
      display: flex;
      flex-direction: column;
      grid-gap: 0.3rem;
      align-items: flex-end;

      .line {
        position: relative;
        display: block;
        width: 100%;
        height: 4px;
        border-radius: 50px;
        background-color: $color-text-inverse;
        transition: width $basic-transition-200;
      }
    }

    &:hover {
      .line {
        &:first-of-type {
          width: 90%;
        }

        &:last-of-type {
          width: 70%;
        }
      }
    }
  }
}

.modal__inner--image {
  pointer-events: none;

  * {
    pointer-events: all;
  }

  div,
  .iiz__img {
    height: 100%;
  }
}

.actions {
  position: absolute;
  bottom: 24px;
  right: 24px;
  display: grid;
  grid-gap: 16px;
  grid-template: 1fr 1fr / 1fr;

  img,
  a {
    display: block;
    width: 25px;
    opacity: 0.8;
    transition: opacity $basic-transition-200;

    &:hover {
      opacity: 1;
    }
  }

  .gallery {
    transform: scale(0.8);
  }

  @include for-tablet-landscape-up {
    bottom: 24px;
    right: 48px;
    grid-template: 1fr / 1fr 1fr;
  }
}

.credit {
  display: block;
  visibility: visible;
  background-color: transparent;
  top: auto;
  z-index: 3;
  font-size: 1.2rem;
  color: #fff;
  letter-spacing: 0;
  text-decoration: none;
  white-space: nowrap;
  bottom: 12px;

  display: none;

  &:hover {
    text-decoration: underline;
  }

  @include for-tablet-landscape-up {
    display: block;
  }
}
