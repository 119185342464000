
            @import "@/scss/common/_vars.scss";
            @import "@/scss/common/_breakpoints.scss";
            @import "@/scss/common/_fonts.scss";
            @import "@/scss/common/_reset.scss";
          


































































































































































































































































































































































































































































@import "../scss/gui.scss";

.krpano__wrapper {
  background: $color-accent;
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;

  div {
    padding: 0;
    margin: 0;
  }

  // CSS Krpano hack
  & #krpanoObject {
    & > div {
      & > div:last-child {
        & > div {
          // #hotspot__wrapper
          width: 0 !important;
          height: 0 !important;
          background-color: transparent !important;
          border: 1px solid transparent;

          &:hover,
          &.locator__touched {
            z-index: 2005 !important;
          }

          & > div {
            & > div {
              overflow: visible !important;

              & > div {
                margin: 0 !important;
              }
            }
          }
        }
      }
    }
  }
}

#krpano {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  background-color: transparent;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity $basic-transition-200 0.1s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-splash-enter-active,
.fade-splash-leave-active {
  transition: opacity $basic-transition-1000;
}
.fade-splash-enter,
.fade-splash-leave-to {
  opacity: 0;
}

.overview {
  z-index: 3000;

  &.fade-enter-active,
  &.fade-leave-active {
    transition: opacity $basic-transition-500;
  }

  &.fade-enter,
  &.fade-leave-to {
    opacity: 0;
  }
}

.logo__wrapper {
  display: none;

  @include for-tablet-landscape-up {
    display: block;
  }
}
