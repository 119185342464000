
            @import "@/scss/common/_vars.scss";
            @import "@/scss/common/_breakpoints.scss";
            @import "@/scss/common/_fonts.scss";
            @import "@/scss/common/_reset.scss";
          





















































































































































$hotspot-dimension: 44px;
$border-width: 2px;

.hotspot {
  /*
    Position on ath/atv : krpano hack
  */
  position: absolute;
  cursor: pointer !important;
  pointer-events: all;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: $hotspot-dimension;
  height: $hotspot-dimension;
  background: $color-white;

  display: grid;
  place-content: center;

  box-shadow: 0 0 32px 8px rgba(0, 0, 0, 0.2);
  transition: all $basic-transition-200;
  border: $border-width solid $color-border;

  img {
    filter: invert(13%) sepia(72%) saturate(5842%) hue-rotate(329deg)
      brightness(79%) contrast(110%);
  }

  &__inner {
    width: $hotspot-dimension;
    height: $hotspot-dimension;
    transition: all $basic-transition-200;
    overflow: hidden;

    img {
      height: 100%;
      width: 100%;
      transition: all $basic-transition-200;
    }
  }

  &__content {
    pointer-events: none;
    position: absolute;
    left: -#{$border-width};
    top: 50%;
    transform: translateY(-50%);
    z-index: -1;

    height: $hotspot-dimension;
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 1.3rem 3.8rem 1.3rem 5rem;
    opacity: 0;
    overflow: hidden;
    transition: all $basic-transition-200, border $basic-transition-300;

    span {
      @include fontSize(1.3rem, 1.3, 1.3rem);
      opacity: 0;
      color: $color-white;
      font-weight: bold;
      transition: all $basic-transition-500;
    }

    &__bg {
      border: calc(#{$border-width} + 1px) solid $color-border;
      background: $color-primary;
      width: 0%;
      // height: calc(#{$hotspot-dimension} + 2 * #{$border-width});
      height: 100%;
      left: 0;
      transition: all $basic-transition-200;
      position: absolute;
      z-index: -1;
    }
  }

  &__navigation:hover,
  &__navigation.touched {
    border: $border-width solid transparent;
    background: transparent;

    .hotspot__inner {
      background: transparent;
      width: calc(#{$hotspot-dimension} - 4px);
      height: calc(#{$hotspot-dimension} - 4px);

      img {
        opacity: 1;
        filter: invert(92%) sepia(100%) saturate(0%) hue-rotate(202deg)
          brightness(106%) contrast(106%);
      }
    }

    .hotspot__content {
      opacity: 1;
      pointer-events: all;

      span,
      p {
        opacity: 1;
      }

      &__bg {
        width: 100%;
      }
    }
  }

  &__info:hover,
  &__image:hover {
    background: $color-primary;
    border: $border-width solid transparent;

    img {
      opacity: 1;
      filter: invert(92%) sepia(100%) saturate(0%) hue-rotate(202deg)
        brightness(106%) contrast(106%);
    }
  }
}
