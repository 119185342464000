
            @import "@/scss/common/_vars.scss";
            @import "@/scss/common/_breakpoints.scss";
            @import "@/scss/common/_fonts.scss";
            @import "@/scss/common/_reset.scss";
          




































































































































.detailed-overview {
  &__container {
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition: all 0.25s ease-out;
    visibility: hidden;

    &_open {
      height: 100%;
      overflow: auto;
      width: 100%;

      .detailed-overview__inner {
        right: 0;
        box-shadow: 4px 0 32px 4px rgba(0, 0, 0, 0.2);
      }

      .detailed-overview__outer {
        pointer-events: all;
        opacity: 1;

        @include for-tablet-landscape-up {
          opacity: 0.8;
        }
      }
    }
  }

  &__inner {
    background-color: rgba($color-text-inverse, 0.9);
    backdrop-filter: blur(5px);
    position: relative;
    right: -100%;
    visibility: visible;
    z-index: 1003;
    pointer-events: all;
    box-shadow: none;
    // max-height: 100%;
    overflow: hidden;
    scroll-behavior: smooth;
    margin: 0 auto;
    width: 100%;
    padding: 4.2rem 2.4rem 10rem 2.4rem !important;
    order: 2;
    min-height: 50%;

    * {
      color: $color-backdrop;
    }

    h2 {
      font-weight: normal;
      text-transform: uppercase;
      @include fontSize(1.4rem, 1.4, 1.4rem);
      margin-bottom: 2.4rem;
    }

    .gui__close {
      display: none;

      img {
        width: 28px;
        height: 28px;
        transition: transform $basic-transition-300;
        filter: invert(37%) sepia(9%) saturate(987%) hue-rotate(164deg)
          brightness(93%) contrast(86%);

        span {
          color: $color-backdrop;
        }
      }

      @include for-tablet-landscape-up {
        display: flex;
      }
    }

    .art-list {
      display: flex;
      flex-direction: column;
      margin: 0;
      padding: 0;
      list-style-type: none;

      &__art {
        width: 100%;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        padding: 2.4rem 0;
        transition: all $basic-transition-200;
        position: relative;

        &:not(:last-child) {
          border-bottom: 1px solid rgba($color-backdrop, 0.2);
        }

        &:hover {
          padding: 2.4rem 0 2.4rem 2.4rem;

          img {
            left: 2.4rem;
          }
        }

        h3 {
          font-weight: bold;
          @include fontSize(1.6rem, 1.7, 1.7rem);
          margin-bottom: 0.3rem;
        }

        span {
          font-weight: normal;
          @include fontSize(1.6rem, 1.7, 1.7rem);
        }

        p {
          font-weight: normal;
          @include fontSize(1.5rem, 1.5, 1.5rem);
        }

        img {
          max-width: 2.4rem;
          position: relative;
          left: 0rem;
          display: block;
          transition: all $basic-transition-300;
          filter: invert(37%) sepia(17%) saturate(454%) hue-rotate(164deg)
            brightness(95%) contrast(96%);

          @include for-tablet-landscape-up {
            display: none;
          }

          &.icon-desktop {
            display: none;

            @include for-tablet-landscape-up {
              display: block;
            }
          }
        }
      }

      .artist {
        display: flex;
        align-items: center;

        img {
          margin-left: 4.2rem;
        }
      }
    }

    .credit {
      color: $color-backdrop;
      opacity: 0.5;
      @include fontSize(1.2rem, 1.2, 1.2rem);
      right: 1rem;
      bottom: 1rem;
      display: block;

      &:hover {
        opacity: 1;
      }
    }

    @include for-tablet-landscape-up {
      position: absolute;
      order: unset;
      width: 50%;
      height: 100%;
      padding: 12.8rem 10rem 10rem 10rem !important;
      transition: right $basic-transition-500 0.1s;
    }
  }

  &__outer {
    visibility: visible;
    position: relative;
    // top: 0;
    // left: 0;
    // right: 0;
    // bottom: 0;
    width: 100%;
    background-color: rgba($color-primary--darker, 1);
    opacity: 0;
    z-index: 1002;
    pointer-events: none;
    order: 1;
    min-height: 50%;

    @include for-tablet-landscape-up {
      transition: opacity $basic-transition-500 0.1s;
      position: fixed;
      order: unset;
      height: 100%;
    }

    .gui__close {
      z-index: 3001;
      display: flex;
      background: $color-primary--dark;
      top: 0;
      right: 0;
      padding: 1.6rem $ui-padding-mobile;

      img {
        width: 28px;
        height: 28px;
        transition: transform $basic-transition-300;
        filter: invert(100%) sepia(100%) saturate(2%) hue-rotate(124deg)
          brightness(106%) contrast(101%);
      }

      span {
        color: $color-white;
      }

      @include for-tablet-landscape-up {
        display: none;
      }
    }
  }

  &__category {
    height: 100%;
    width: 100%;
    position: relative;
    padding: 9rem 2.4rem 10rem 2.4rem !important;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    * {
      color: $color-white;
    }

    @include for-tablet-landscape-up {
      width: 50%;
      padding: 12.8rem 12rem 10rem 8rem !important;
    }

    a {
      position: relative;
      display: flex;
      align-items: center;
      @include fontSize(1.4rem, 1.4, 1.4rem);
      font-weight: normal;
      text-decoration: none;
      margin-bottom: 3.6rem;
      left: 4.2rem;

      @include for-tablet-landscape-up {
        margin-bottom: 8.2rem;
        left: 0;
      }

      img {
        position: absolute;
        max-width: 2rem;
        top: 50%;
        left: -4.2rem;
        transform: translateY(-50%) rotate(-180deg);
        transition: all $basic-transition-200;
        filter: invert(100%) sepia(100%) saturate(2%) hue-rotate(124deg)
          brightness(106%) contrast(101%);
      }

      &:hover img {
        left: -3.6rem;
      }
    }

    h2 {
      font-weight: bold;
      @include fontSize(4rem, 5.6, 5.6rem, 1);
      margin-bottom: 2rem;
    }

    p {
      @include fontSize(1.5rem, 1.5, 1.5rem, 1.7);
    }
  }
}
