
            @import "@/scss/common/_vars.scss";
            @import "@/scss/common/_breakpoints.scss";
            @import "@/scss/common/_fonts.scss";
            @import "@/scss/common/_reset.scss";
          


























































































.home {
  &__info {
    background: $color-primary;
    color: $color-text-inverse;
    padding: 3.2rem 2.4rem;

    width: 100%;
    max-width: unset;
    left: 0;
    right: 0;
    bottom: 0;

    display: grid;
    justify-content: flex-start;
    align-content: space-between;
    grid-gap: 24px;
    overflow: hidden;

    &--emphasized {
      font-weight: bold;
      margin-bottom: 2.4rem;
    }

    @include for-tablet-landscape-up {
      padding: 56px;
      max-width: 50rem;
      width: 40%;
      left: unset;
      right: $ui-padding;
      bottom: calc(#{$ui-padding} + 7.4rem);
    }
  }

  .logo__wrapper {
    max-width: 12rem;

    img {
      width: 100%;
      object-fit: cover;
    }

    &--inside {
      display: block;
      justify-self: flex-end;
    }

    &--outside {
      display: none;
    }

    @include for-tablet-landscape-up {
      &--inside {
        display: none;
      }

      &--outside {
        display: block;
      }
    }
  }

  &__video {
    z-index: -3;
    object-fit: cover;

    &-bg {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;

      * {
        position: absolute;
        height: 100%;
        width: 100%;
      }
    }

    &--overlay {
      z-index: -2;
      height: 100%;
      width: 100%;
      opacity: 0.7;
      background: lighten($color-black, 25%);
    }
  }

  .gui__logo {
    z-index: 11;
  }

  .overview {
    z-index: 10;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity $basic-transition-500;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
}
